<template>
  <div style="display: inline-block">
    <el-dialog
      :visible.sync="dialog"
      :title="title"
      :close-on-click-modal="false"
      append-to-body
      width="500px"
      @close="cancel"
      v-dialogDrag
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="88px"
      >
        <el-form-item label="旧密码" prop="oldPass">
          <el-input
            v-model="form.oldPass"
            type="password"
            auto-complete="on"
            style="width: 370px;"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input
            v-model="form.newPass"
            type="password"
            auto-complete="on"
            style="width: 370px;"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPass">
          <el-input
            v-model="form.confirmPass"
            type="password"
            auto-complete="on"
            style="width: 370px;"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit('form')"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
// import { validPass, updatePass } from "@/api/user";
// import md5 from "js-md5";
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value) {
        // validPass(md5(value)).then(res => {
        //   if (res.status === 200) {
        //     callback()
        //   } else {
        //     callback(new Error('旧密码错误，请检查'))
        //   }
        // })
        // validPass(value).then(res => {
        //   if (res.status === 200) {
        //     callback();
        //   } else {
        //     callback(new Error("旧密码错误，请检查"));
        //   }
        // });
      } else {
        callback(new Error("请输入旧密码"));
      }
    };
    const confirmPass = (rule, value, callback) => {
      if (this.form.newPass !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      dialog: false,
      title: "修改密码",
      form: { oldPass: "", newPass: "", confirmPass: "" },
      rules: {
        oldPass: [{ required: true, validator: validatePass, trigger: "blur" }],
        newPass: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
        ],
        confirmPass: [
          { required: true, validator: confirmPass, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        console.log("valid", valid);
        if (valid) {
          var regex = new RegExp(
            "(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}"
          );
          if (!regex.test(this.form.confirmPass)) {
            alert(
              "您的密码复杂度太低（密码中必须包含字母、数字、特殊字符），请重新设置密码！"
            );
            return false;
          } else {
            this.loading = true;
            // updatePass(md5(this.form.oldPass), md5(this.form.confirmPass)).then(res => {
            //   this.resetForm()
            //   this.$notify({
            //     title: '密码修改成功，请重新登录',
            //     type: 'success',
            //     duration: 1500
            //   })
            //   setTimeout(() => {
            //     store.dispatch('LogOut').then(() => {
            //       location.reload() // 为了重新实例化vue-router对象 避免bug
            //     })
            //   }, 1500)
            // }).catch(err => {
            //   this.loading = false
            //   console.log(err.response.data.message)
            // })
            // updatePass(this.form.oldPass, this.form.confirmPass)
            //   .then(res => {
            //     this.resetForm();
            //     this.$notify({
            //       title: "密码修改成功，请重新登录",
            //       type: "success",
            //       duration: 1500
            //     });
            //     setTimeout(() => {
            //       store.dispatch("LogOut").then(() => {
            //         location.reload(); // 为了重新实例化vue-router对象 避免bug
            //       });
            //     }, 1500);
            //   })
            //   .catch(err => {
            //     this.loading = false;
            //     console.log(err.response.data.message);
            //   });
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = { oldPass: "", newPass: "", confirmPass: "" };
    }
  }
};
</script>

<style scoped></style>
