<template>
  <div class="home">
    <!-- <navbar></navbar> -->
    <div class="navbar-wrapper">
      <div class="flex common-content navbar-container">
        <div class="flex-center navbar-logo" @click.stop="goHome">
          <img src="~images/home/logo.png" alt="" />
        </div>
        <div style="height:100%;padding-bottom: 0;margin-left:84px;">
          <div class="flex navbar-box">
            <div
              class="flex-center navbar-item"
              v-for="(navbar, index) in navbarList"
              :key="index"
              :class="{ 'navbar-active': routerName == navbar.url }"
              @click.stop="goPath(navbar)"
            >
              <img
                :src="routerName == navbar.url ? navbar.img : navbar.imgActive"
                alt=""
                class="navbar-img"
              />
              {{ navbar.name }}
            </div>
          </div>
        </div>
        <userInfo></userInfo>
      </div>
    </div>

    <div class="main-container">
      <!-- <transition name="move" mode="out-in"> -->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
      <!-- </transition> -->
    </div>
    <div class="flex-center main-footer">
      <a href="https://beian.miit.gov.cn" target="block">
        粤ICP备2023025478号
      </a>
    </div>
    <updatePass ref="updatePwd"></updatePass>
  </div>
</template>

<script>
import userInfo from "@/components/common/userInfo";
import { mapGetters } from "vuex";
import updatePass from "./common/updatePass";
export default {
  name: "home",
  components: { updatePass, userInfo },
  data() {
    return {
      list: [
        {
          name: "个人中心",
          url: "userCenter"
        },
        {
          name: "帮助中心",
          url: "operation"
        },
        {
          name: "消息中心",
          url: "message"
        },
        {
          name: "修改密码",
          url: "changePwd"
        },
        {
          name: "退出登录",
          url: "signOut"
        }
      ],
      navbarList: [
        {
          name: "工作台",
          url: "staging",
          imgActive: require("images/home/icon_work_active.png"),
          img: require("images/home/icon_work.png")
        },
        {
          name: "定价",
          url: "member",
          imgActive: require("images/home/icon_dj_active.png"),
          img: require("images/home/icon_dj.png")
        },
        {
          name: "帮助中心",
          url: "operation",
          imgActive: require("images/home/icon_help_active.png"),
          img: require("images/home/icon_help.png")
        },
        {
          name: "消息中心",
          url: "message",
          imgActive: require("images/home/icon_msg_active.png"),
          img: require("images/home/icon_msg.png")
        }
      ],
      msgNum: 10
    };
  },
  created() {},
  activated() {},
  computed: {
    ...mapGetters(["routerName", "userInfo"])
  },
  mounted() {},
  methods: {
    goHome() {
      this.$router.replace("/");
    },
    // 操作方法
    handleCommand(command) {
      if (command === this.routerName || !command) return;
      if (command == "changePwd") this.$refs.updatePwd.dialog = true;
      else if (command == "signOut") this.$store.dispatch("user/loginOut");
      else this.$router.push(command);
    },
    goMsg() {
      this.$router.push("message");
    },

    // 跳转页面
    goPath(item) {
      if (item.url === this.routerName) return;
      this.$router.push(item.url);
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>

.home
  position: relative
  top 0
  left 0
  right: 0
  bottom: 0
  .navbar-container
    position: relative
  .navbar-wrapper
    position: relative
    padding 0px 16px
    height 58px
    background: #0b2b52
    align-items: center
    color #fff
    justify-content: space-around
  .navbar-logo
    cursor pointer
    img
      width 180px
      height 32px
  .navbar-box
     font-size 18px
     color #fff
     height 100%
     align-items: center
     font-weight: 700
     .navbar-item
        position: relative
        overflow hidden
        width 100px
        height 38px
        border-radius: 2px
        margin-right: 24px
        cursor pointer
        &:last-child
          margin-right 0
     .navbar-img
        width 16px
        height 16px
        margin-right 4px
     .navbar-active
        color #0B2B52
        background: #ffffff
</style>
